<template>
  <div class="flex flex-col">
    <div class="flex flex-col p-4">
      <h1 class="font-semibold text-primary">Destinations</h1>
      <p>
        {{ `${numberOfResults} résultat${numberOfResults > 1 ? 's' : ''}` }}
      </p>
    </div>
    <div class="flex justify-between">
      <div v-if="numberOfResults > 0" class="flex w-full items-center bg-white">
        <SliderSwiperArrows v-if="numberOfResults > 3" v-model="swiper" class="p-1" which="left" />
        <SliderSwiperWrapper v-model="swiper" slides-per-view="auto" :active-index="1">
          <SwiperSlide v-for="destination of destinations" :key="destination.objectID">
            <NuxtLink :key="destination.objectID" class="tag" :to="`/${destination.urlPath}`">
              {{ destination.title }}
            </NuxtLink>
          </SwiperSlide>
        </SliderSwiperWrapper>
        <SliderSwiperArrows v-if="numberOfResults > 3" v-model="swiper" class="p-3" which="right" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AlgoliaDestination } from '@/lib/algolia-types/AlgoliaDestination';

import { SwiperBinding } from '@/lib/types/models/swiper';

defineProps<{
  destinations: AlgoliaDestination[];
  numberOfResults: number;
}>();

const swiper = ref<SwiperBinding>();
</script>

<style lang="scss" scoped>
@use '$/button.scss';
@use '$/breakpoints.scss';

.tag {
  @include button.outlined();

  @include breakpoints.mobile() {
    margin: 0 6px;
  }

  margin: 0 15px;
  white-space: nowrap;
  background-color: white;
}
</style>

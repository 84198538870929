const headerEventPayload = ref<{ openSearchModal: boolean } | null>(null);

export const useHeaderEvent = () => {
  const setHeaderEventPayload = (payload: { openSearchModal: boolean }) => {
    headerEventPayload.value = payload;
  };

  return {
    headerEventPayload,
    setHeaderEventPayload
  };
};
